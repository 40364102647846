<template>
    <div>
        <b-row if="articles.length >= 1">
            <div class="col-md-12 text-right">
                <button class="btn btn-sm btn-warning" @click="exportLocafox"><i class="fas fa-file-export"></i> Locafox Export</button>
            </div>
        </b-row>
        <b-row v-if="articles.length >= 1" class="mt-2">
            <b-col>
                <vue-good-table 
                    :columns="columns"
                    :rows="articles"
                    :search-options="{ 
                        enabled: true
                    }"
                    :pagination-options="{
                        enabled: true,
                        mode: 'pages',
                        perPage: 20,
                        perPageDropdownEnabled: true,
                        nextLabel: 'Nächste',
                        prevLabel: 'Vorherige',
                        rowsPerPageLabel: 'Zeilen pro Seite',
                        ofLabel: 'von',
                        pageLabel: 'Seite',
                        allLabel: 'Alle',
                    }"
                    styleClass="vgt-table condensed striped">

                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'action'">
                            <router-link class="btn btn-success btn-sm mr-1" :to="{name: 'articles.show', params: {id: props.row.id}}" v-if="$auth.check('articles.show')"><i class="fas fa-eye"></i></router-link>
                            <router-link class="btn btn-warning btn-sm mr-1" :to="{name: 'articles.details', params: {id: props.row.id}}" v-if="$auth.check('articles.show')"><i class="fas fa-edit"></i></router-link>
                        </span>  

                        <span v-if="props.column.field == 'active'">
                            <span v-if="props.row.active == 1">
                                <i class="fas fa-check ml-2"></i>
                            </span>

                            <span v-if="props.row.active == 0">
                                <i class="fas fa-times ml-2"></i>
                            </span>
                        </span>

                        <span v-else>
                            {{props.formattedRow[props.column.field]}}
                        </span>    
                    </template>
                    
                </vue-good-table>
            </b-col>
        </b-row>

        <b-row v-if="articles.length == 0">
            <b-col>
                <h4>Dieser Hersteller hat keine Artikel</h4>
            </b-col>
        </b-row>
    </div>     
</template>

<script>
export default {

    name: 'Articles',

    props: [
        'articles',
    ],

    data(){
        return {
            columns: [
                {field: "active", label: "Aktiv"},
                // {field: "distributor.name", label: "Lieferant"},
                {field: "ordernumber", label: "Artikelnummer"},
                {field: "name", label: "Name"},
                {field: "tax.description", label: "Steuersatz"},
                {field: 'price', label: "Preis (Brutto)"},
                {field: "action", label: "Aktion", tdClass: 'text-center', width: '7%'},
            ],
        }
    },

    methods: {
        exportLocafox(){
            this.$Progress.start();
            this.axios
                .get("/producers/" + this.$route.params.id + '/articles/export/locafox',{
                    responseType: 'blob',
                })
                .then((response) => {
                    this.$Progress.finish();
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
                    );

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", 'Locafox.xlsx');
                    document.body.appendChild(link);

                    // Dynamicall click the a link element
                    // This will download the csv file
                    link.click();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }
    }

}
</script>

<style>

</style>